$green: rgb(28, 156, 16);

body > ul.nav {
    background-color: $green;
}

.text-success {
    color: $green;
}

.nav.nav-pills.nav-fill {
    background-color: rgba(39, 153, 28, 0.7);
}

.footer {
    background-color: $green;
    color: white;
}